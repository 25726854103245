<template>
  <div>
    <van-form @submit="onSubmit">
      <!--账号-->
      <van-field v-model="account" name="account" label="账号" placeholder="账号" @input="accountChange"
        @blur="getAccountInfo" :rules="[
          // { required: true, message: '请输入账号', validator: asyncValidator },
          { required: true, message: '请输入账号' },
        ]">
        <van-button v-show="accountList.length > 0" slot="button" size="small" type="primary"
          @click="accountShowPicker = true" native-type="button">历史账号</van-button>
      </van-field>
      <van-popup v-model="accountShowPicker" position="bottom">
        <van-picker show-toolbar :columns="accountList" @confirm="accountConfirm" @cancel="accountShowPicker = false" />
      </van-popup>

      <!--系统-->
      <!--      <van-field-->
      <!--          readonly-->
      <!--          clickable-->
      <!--          name="os"-->
      <!--          :value="osText"-->
      <!--          label="系统"-->
      <!--          placeholder="选择系统"-->
      <!--          @click="osShowPicker = true"-->
      <!--          :rules="[{ required: true }]"-->
      <!--      />-->
      <!--      <van-popup v-model="osShowPicker" position="bottom">-->
      <!--        <van-picker-->
      <!--            show-toolbar-->
      <!--            :columns="osList"-->
      <!--            @confirm="osConfirm"-->
      <!--            @cancel="osShowPicker = false"-->
      <!--        />-->
      <!--      </van-popup>-->

      <!--游戏-->
      <!--      <van-field-->
      <!--          readonly-->
      <!--          clickable-->
      <!--          name="game"-->
      <!--          :value="gameText"-->
      <!--          label="游戏"-->
      <!--          placeholder="选择游戏(请先选择系统)"-->
      <!--          @click="gameShowPicker = true"-->
      <!--          :rules="[{ required: true }]"-->
      <!--      />-->
      <!--      <van-popup v-model="gameShowPicker" position="bottom">-->
      <!--        <van-picker-->
      <!--            show-toolbar-->
      <!--            :columns="gameList"-->
      <!--            @confirm="gameConfirm"-->
      <!--            @cancel="gameShowPicker = false"-->
      <!--        />-->
      <!--      </van-popup>-->

      <van-notice-bar v-if="sale_info" wrapable :scrollable="false" :text="sale_info" />

      <!--区服-->
      <van-field readonly clickable name="server" :value="serverText" label="区服" placeholder="选择区服"
        @click="serverShowPicker = true" :rules="[{ required: true }]" />
      <van-popup v-model="serverShowPicker" position="bottom">
        <van-picker show-toolbar :columns="getServerList" :loading="serverLoading" @confirm="serverConfirm"
          @cancel="serverShowPicker = false" />
      </van-popup>

      <!--角色-->
      <van-field readonly clickable name="role" :value="roleText" label="角色名" placeholder="选择角色(请先选择区服)"
        @click="roleShowPicker = true" :rules="[{ required: true }]" />
      <van-popup v-model="roleShowPicker" position="bottom">
        <van-picker show-toolbar :columns="roleList" :loading="roleLoading" @confirm="roleConfirm"
          @cancel="roleShowPicker = false" />
      </van-popup>

      <!--金额-->
      <van-field readonly clickable name="money" :value="moneyText" label="充值档位" placeholder="选择档位"
        @click="moneyShowPicker = true" :rules="[{ required: true }]" />
      <van-popup v-model="moneyShowPicker" position="bottom">
        <van-picker show-toolbar :columns="moneyList" :loading="moneyLoading" @confirm="moneyConfirm"
          @cancel="moneyShowPicker = false" />
      </van-popup>

      <div class="van-cell" style="color: red;" v-show="discountTipsShow == true">
        <div class="van-cell__title">
          <span>{{ discountTips }}</span>
        </div>
      </div>

      <!--充值道具-->
      <van-field v-model="product_name" name="product_name" label="充值金额" placeholder="" readonly />

      <div v-if="payTypeId == 20 && (!user_email || user_email == '')">
        <van-field v-model="first_name" maxlength="10" name="first_name" label="姓" placeholder="请输入姓"
          :rules="[{ required: true, message: '请输入姓' }]" />
        <van-field v-model="last_name" maxlength="10" name="last_name" label="名字" placeholder="请输入名字"
          :rules="[{ required: true, message: '请输入名字' }]" />
        <van-field v-model="email" name="email" maxlength="30" label="邮箱" placeholder="请输入邮箱"
          :rules="[{ required: true, message: '请输入邮箱' }, { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: '邮箱格式错误！' }]" />
      </div>


      <!--支付方式-->
      <van-cell title="支付方式" style="text-align: center"> </van-cell>

      <div class="payWrap">
        <van-radio-group v-model="payTypeId">
          <div class="payitem" v-for="item in payTypeList" :key="item.type" @click="payTypeId = item.type">
            <img class="payitem_icon" :src="item.src" alt="">
            <div class="payDesc">{{ item.desc }}</div>
            <van-radio :name="item.type"></van-radio>
          </div>
        </van-radio-group>
        <div class="info">外币结算汇率按照实际汇率实时变动</div>
      </div>
      <div style="margin: 16px">
        <van-button style="width: 45%;float:right" type="primary">立即充值</van-button>
        <van-button style="width: 45%; float: left" type="info" @click="reset" native-type="button">重置</van-button>
      </div>
    </van-form>
    <!-- 优惠券列表 -->
    <van-popup v-model="showCouponList" round position="bottom" style="height: 90%; padding-top: 4px">
      <van-coupon-list :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons"
        :show-exchange-bar="false" @change="onCouponChange" />
    </van-popup>
  </div>
</template>

<script>
import {
  accountInfo,
  // roleList,
  // serverList,
  thirdProductList,
  getServerList,
  getRoleList,
  getGameId,
  commonCreateOrder,
  payMix,
  payWxRedirect, payLianLian, payMycard
} from "@/api/rxhj";
import {
  stripePayment,
  getUsdMoney,
  AirwallexPayment
} from "@/api/stripePay";
import { Toast } from "vant";
import { Base64 } from "js-base64";
import { couponList } from "@/api/user";
import { getDiscountInfo } from "@/api/common/discount";
import { redirectToCheckout, loadAirwallex } from 'airwallex-payment-elements';
import { isSysName } from "@/utils";
loadAirwallex({
  env: 'prod', // Can choose other production environments, 'staging | 'demo' | 'prod'
});
const PAY_Airwallex = 20
const PAY_TYPE_WX = 2
const PAY_TYPE_ALIPAY = 1
const PAY_TYPE_PAYPAL = 3
const PAY_PAL_CARD = 14
const PAY_LIAN_LIAN = 13
const PAY_MYCARD_CARD = 15
const PAY_STRIPE = 10
const PAY_Google = 21
const PAY_Apple = 22
export default {
  // components: {
  //   vanCouponCell: CouponCell,
  //   vanCouponList: CouponList,
  // },
  computed: {
    filteredPayTypeList() {
      return this.payTypeList.filter(item => item.type != 22 || isSysName() == 'ios');
    },
  },
  data() {
    return {
      payTypeList: [
        {
          type: '2',
          desc: '人民币',
          src: require('@/assets/credit/pay_icon/wechat.png')
        },
        {
          type: '1',
          desc: '人民币',
          src: require('@/assets/credit/pay_icon/alipay.png')
        },
        {
          type: '21',
          desc: '外币',
          src: require('@/assets/credit/pay_icon/google.png')
        },
        {
          type: '22',
          desc:'外币',
          src: require('@/assets/credit/pay_icon/apple.png')
        },
        {
          type: '3',
          desc: '外币',
          src: require('@/assets/credit/pay_icon/paypal.png')
        },
        {
          type: '14',
          desc: '外币',
          src: require('@/assets/credit/pay_icon/pcard.png')
        },
        {
          type: '20',
          desc: '外币',
          src: require('@/assets/credit/pay_icon/pcard3.png')
        },
        {
          type: '15',
          desc: '外币',
          src: require('@/assets/common/mycard.png')
        },
      ],
      first_name: '',
      last_name: '',
      email: '',
      account: "", //账号
      test_show: true,
      accountList: [],
      mycardShow: true,//  mycard测试
      accountShowPicker: false,
      user_id: 0, //用户id
      user_email: '',
      game_id: "", //游戏id
      is_sale: 0, //是否打折 0不打折
      sale_info: "", //销售信息
      discount_info: "恭喜你，本次充值额外赠送10%！", //折扣信息
      discount_show: false, //显示打折信息
      discountTips: "", //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      discountTipsShow: false, //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      money: '', //用户充值金额
      product_id: 0, //后台自增id 非product_id
      product_name: "", //物品名
      payState: false, //防止重复点击
      // osId: "",
      // osText: "", //选中内容
      // osList: ["苹果", "安卓"], //系统列表
      // osShowPicker: false,
      // gameText: "光辉龙城", //选中游戏
      // gameList: [], //游戏列表
      // gameShowPicker: false,
      serverText: "", //选中区服
      server_id: "", //选中区服ID
      role_id: 0, //选中角色ID
      getServerList: [], //区服列表
      serverLoading: false,
      serverShowPicker: false,
      roleText: "", //选中角色
      roleList: [], //角色列表
      roleLoading: false,
      roleId: 0,
      roleShowPicker: false,
      moneyText: "", //选中金额
      moneyList: [], //金额列表
      moneyLoading: false,
      moneyShowPicker: false,
      payTypeId: "2",
      chosenCoupon: -1,
      showCouponList: false,
      coupons: [],
      couponList: [],
      disabledCoupons: [],
      couponId: undefined,
      discount: false,
      discountText: ""
    };
  },
  mounted: function () {
    const img = document.querySelector(`img[src="https://wx.playhy.com/card-mark.png"]`);
    if (img) {
      img.remove()
    }
    const p = document.querySelector(`p`);
    if (p) {
      p.remove()
    }
  },
  created() {
    this.$nextTick(() =>{
      this.payTypeId = localStorage.payTypeId || '2';
    })
    if (localStorage.rxhj_info) {
      const rxhj_info =
        typeof localStorage.rxhj_info === "string"
          ? JSON.parse(localStorage.rxhj_info)
          : localStorage.rxhj_info;
      this.accountList = Object.keys(rxhj_info);
      console.log(this.accountList);
    }

    if (localStorage.rxhjAccountInfo) {
      let account_item = JSON.parse(localStorage.rxhjAccountInfo);
      console.log(account_item)
      // 填充数据
      this.account = account_item.account;
      this.user_id = account_item.user_id;
      this.user_email = account_item.user_email;
      this.game_id = account_item.game_id;
      // this.gameText = account_item.gameText;
      // this.osId = account_item.osId;
      // this.osText = account_item.osText;
      this.serverText = account_item.serverText;
      this.roleText = account_item.roleText;
      this.roleId = account_item.roleId;
      this.sale_info = account_item.sale_info;
      // this.gameList = account_item.gameList;
      this.getServerList = account_item.getServerList;
      this.roleList = account_item.roleList;

      //  ----获取折扣信息接口Start----
      this.getDiscountInfo({ 'game_slug': this.game_id });
      //  ----获取折扣信息接口End----
      this.roleConfirm({ id: this.roleId, text: this.roleText });
    }
  },
  methods: {
    //  获取折扣信息接口
    getDiscountInfo(params) {
      getDiscountInfo(params).then((response) => {
        console.log(response)
        console.log(response.content)
        if (response.content) {
          this.discountTipsShow = true;
          this.discountTips = response.content
        } else {
          this.discountTipsShow = false;
        }
        // this.discountTips = response.content;
      }).catch(() => {
        //  折扣信息接口不存在
        this.discountTipsShow = false;
      });
    },
    validator(val) {
      if (val >= 10 && val <= 10000) {
        return true
      }
      return false;
    },
    onSubmit() {
      console.log("onSubmit");
      if(this.payTypeId == 22 && isSysName() != 'ios'){
        this.$toast.fail('仅限苹果手机使用')
        return
      }
      const that = this;
      if (that.payState) {
        return false;
      }
      that.payState = true;
      Toast.loading({
        message: "登录中，请稍等!",
        forbidClick: true,
      });
      console.log("payTypeId", parseInt(this.payTypeId));
      this.saveUserRole()
      // console.log(localStorage.rxhj_info);
      this.orderStore()

    },
    reset: function () {
      this.changeReset("all");
    },
    accountConfirm(value) {
      this.accountShowPicker = false;
      if (localStorage.rxhj_info) {
        const rxhj_info =
          typeof localStorage.rxhj_info === "string"
            ? JSON.parse(localStorage.rxhj_info)
            : localStorage.rxhj_info;
        if (rxhj_info[value]) {
          const account_item = rxhj_info[value];
          console.log("选中账号", JSON.stringify(account_item));

          // 填充数据
          this.reset();
          this.account = account_item.account;
          this.user_id = account_item.user_id;
          this.user_email = account_item.user_email;
          this.game_id = account_item.game_id;
          // this.gameText = account_item.gameText;
          // this.osId = account_item.osId;
          // this.osText = account_item.osText;
          this.serverText = account_item.serverText;
          this.server_id = account_item.server_id;
          this.roleText = account_item.roleText;
          this.roleId = account_item.roleId;
          this.role_id = account_item.role_id;
          this.sale_info = account_item.sale_info;
          // this.gameList = account_item.gameList;
          this.getServerList = account_item.getServerList;
          this.roleList = account_item.roleList;

          //  ----获取折扣信息接口Start----
          this.getDiscountInfo({ 'game_slug': this.game_id });
          //  ----获取折扣信息接口End----
          this.roleConfirm({ id: this.roleId, text: this.roleText, server_id: this.server_id, role_id: this.role_id });
        }
        // 获取优惠券
        // this.getCouponList();
      }
    },
    // osConfirm(value) {
    //   this.osText = value;
    //   this.osShowPicker = false;
    //   this.osId = value == "安卓" ? 1 : 2;
    //   this.changeReset("os");
    //   this.getGameList({ os: this.osId });
    // },
    // gameConfirm(value) {
    //   if (this.account == "" || this.user_id == 0) {
    //     Toast.fail("请先输入账号");
    //     return false;
    //   }
    //   this.game_id = value.slug;
    //   this.gameText = value.text;
    //   this.gameShowPicker = false;
    //   this.sale_info = value.sale_info;
    //   this.changeReset("game");
    //
    //   //  ----获取折扣信息接口Start----
    //   this.getDiscountInfo({'game_slug':this.game_id});
    //   //  ----获取折扣信息接口End----
    //
    //
    //   getServerList({ game_id: this.game_id, os: this.osId })
    //       .then((response) => {
    //         if (response.length == 0) {
    //           this.$toast({
    //             message: "该游戏暂无区服",
    //             duration: 500,
    //           });
    //         } else {
    //           this.$toast({
    //             message: "获取区服列表成功",
    //             duration: 500,
    //           });
    //           this.getServerList = response;
    //           this.roleText = this.moneyText = "";
    //           this.roleId = 0;
    //           this.role_id = 0;
    //           this.roleList = this.moneyList = [];
    //         }
    //       })
    //       .catch(() => {});
    // },
    get_server_list(params) {
      this.serverLoading = true;
      getServerList(params)
        .then((response) => {
          console.log("获取区服列表信息" + response);
          this.serverLoading = false;
          if (response.length == 0) {
            this.$toast({
              message: "该游戏暂无区服",
              duration: 500,
            });
          } else {
            this.$toast({
              message: "获取区服列表成功",
              duration: 500,
            });
            this.getServerList = response;
            this.roleText = this.moneyText = "";
            this.roleId = 0;
            this.roleList = this.moneyList = [];
          }
        })
        .catch(() => { });
    },
    serverConfirm(value) {
      this.serverText = value.text;
      this.server_id = value.server_id;
      this.serverShowPicker = false;
      this.roleLoading = true;
      this.roleList = [];
      this.changeReset("server");
      getRoleList({
        user_id: this.user_id,
        server: this.serverText,
      })
        .then((response) => {
          this.roleLoading = false;
          if (response.length == 0) {
            this.$toast({
              message: "该区服,您尚未创建角色",
              duration: 1000,
            });
          } else {
            this.$toast({
              message: "获取角色列表成功",
              duration: 500,
            });
            this.roleList = response;
          }
        })
        .catch(() => { });
    },
    roleConfirm(value) {
      this.roleText = value.text;
      this.roleId = value.id;
      this.role_id = value.role_id;
      this.server_id = value.server_id;
      this.roleShowPicker = false;
      this.moneyLoading = true;
      this.changeReset("role");
      //  获取热血合击档位列表
      thirdProductList().then((response) => {
        this.moneyLoading = false;
        this.$toast({
          message: "获取充值金额列表成功",
          duration: 500,
        });
        const newResponse = response.map(item => {
          return { money: item.money, text: item.name, goods_id: item.id }
        })
        this.moneyList = newResponse;
      }).catch(() => {

      });
      //  获取档位ID platform
      getGameId({ role_id: this.roleId }).then((response) => {
        if (response.length == 0) {
          this.$toast({
            message: "充值链接有误",
            duration: 1000,
          });
        }
        this.game_id = response.game_id;
        this.getDiscountInfo({ 'game_slug': this.game_id });
      })
    },
    moneyConfirm(value) {
      console.log(value)
      this.moneyText = value.text;
      this.money = value.money;
      this.product_name = value.money;
      this.product_id = value.goods_id;
      this.moneyShowPicker = false;
      this.discount_show = false;
      this.coupons = [];
      this.disabledCoupons = [];
      console.log('this.moneyText：' + this.moneyText)
      console.log('this.money：' + this.money)
      console.log('this.product_id：' + this.product_id)
    },

    getAccountInfo() {
      console.log("获取账号信息：" + this.account)
      accountInfo({ account: this.account })
        .then((response) => {
          this.user_id = response.id;
          this.user_email = response.email == '' ? this.user_email : response.email;
          this.get_server_list({ user_id: this.user_id });
        })
        .catch(() => { });
    },
    // asyncValidator(val) {
    //   accountInfo({ account: val })
    //       .then((response) => {
    //         this.user_id = response.id;
    //         // 获取优惠券
    //         // this.getCouponList();
    //       })
    //       .catch(() => {});
    // },
    accountChange() {
      this.changeReset("account");
    },
    // getGameList(value) {
    //   if (value.os == 2) {
    //     // IOS
    //     this.gameList = [
    //       {slug: "133c533a0fb54b30af209f2d945b11ec", text: "光辉龙城", is_sale: 0, sale_info: ""}
    //     ]
    //   } else {
    //     // 获取安卓游戏列表
    //     gameList(value).then((response) => {
    //       this.$toast({
    //         message: "获取游戏列表成功",
    //         duration: 500,
    //       });
    //       this.gameList = response;
    //     }).catch(() => {
    //     });
    //   }
    // },
    changeReset(changeSetup) {
      this.product_id = 0;
      switch (changeSetup) {
        case "all":
          this.user_id = 0;
          this.account = "";
        /* falls through */
        case "account":
          this.osId = "";
          this.osText = "";
          console.log("changeSetup account");
        /* falls through */
        case "os":
          console.log('osid:', this.osId)
          this.game_id = '';
          if (this.osId == 1) {

            this.gameList = [{}];
          } else {

            this.gameList = [];
          }
          this.gameText = "";

          this.sale_info = "";

        /* falls through */
        case "game":
          console.log('osid1:', this.osId)
          this.serverText = "";
          this.server_id = "";
          this.getServerList = [];
        /* falls through */
        case "server":
          this.roleText = "";
          this.roleId = 0;
          this.role_id = 0;
          this.roleList = [];
        /* falls through */
        case "role":
          this.moneyText = this.product_name = "";
          this.moneyList = [];
          this.discount_show = false;
          this.payTypeId = "2";
          break;
      }
    },
    onCouponChange(index) {
      this.showCouponList = false
      this.chosenCoupon = index
      if (index >= 0) {
        this.couponId = this.coupons[index]["coupon_id"]
      } else {
        this.couponId = undefined
      }
    },
    getCouponList() {
      couponList({ account: this.account, user_id: this.user_id }).then(
        (response) => {
          this.couponList = response;
          this.coupons = [];
          this.disabledCoupons = [];
          this.chosenCoupon = -1;
        }
      );
    },
    resetMoney() {
      this.moneyText = this.product_name = this.product_id = undefined
      // this.getCouponList()
    },
    // 创建订单
    orderStore() {
      if (this.product_id == null || this.product_id.length <= 0) {
        this.$toast.fail('请先选择档位')
      }
      const payTypeId = parseInt(this.payTypeId)
      console.log('product_id：' + this.product_id)
      commonCreateOrder({
        user_id: this.user_id,
        game_id: this.game_id,
        server: this.serverText,
        role_name: this.roleText,
        product_id: this.product_id,
        money: this.money,
        os: this.osText,
        osId: 1,
        pay_type_id: payTypeId,
        coupon_id: this.couponId,
      }).then((response) => {
        this.resetMoney()
        this.payState = false
        // 如果代金券直接减完支付完成
        if (response.pay_success && response.pay_success === true) {
          this.$toast.success('支付成功')
        } else {
          this.toPay(response.number)
        }
      }).catch(() => {
        this.payState = false
      })
    },
    // 发起支付
    toPay(orderNum) {
      Toast.loading({
        message: "发起支付中，请稍等!",
        forbidClick: true,
        duration: 0,
      });
      const payTypeId = parseInt(this.payTypeId)
      if (payTypeId === PAY_TYPE_WX) {
        this.payWx(orderNum)
      } else if (payTypeId === PAY_TYPE_ALIPAY) {
        this.payAlipay(orderNum)
      } else if (payTypeId === PAY_MYCARD_CARD) {
        this.payByMyCard(orderNum)
      } else if (payTypeId === PAY_TYPE_PAYPAL) {
        this.payPaypal(orderNum)
      } else if (payTypeId === PAY_PAL_CARD) {
        this.payPaypalCard(orderNum)
      } else if (payTypeId === PAY_LIAN_LIAN) {
        this.payByLianLian(orderNum)
      } else if (payTypeId === PAY_STRIPE) {
        this.payByStripe(orderNum)
      } else if (payTypeId === PAY_Airwallex) {
        this.payByAirwallex(orderNum)
      }else if (payTypeId === PAY_Google) {
        this.googlePay(orderNum)
      } else if (payTypeId === PAY_Apple) {
        this.applePay(orderNum)
      }
    },
    //空中云汇支付
    payByAirwallex(orderNum) {
      let email = this.user_email == '' ? this.email : this.user_email;
      AirwallexPayment({
        order_num: orderNum,
        first_name: this.first_name,
        last_name: this.last_name,
        email: email,
      }).then((response) => {
        console.log(response)

        const intent_id = response.intent_id;
        const client_secret = response.client_secret;
        const currency = response.currency;
        redirectToCheckout({
          env: 'prod',
          mode: 'payment',
          currency,
          intent_id, // Required, must provide intent details
          client_secret, // Required
          theme: {
            // Must provide theme to display the checkout page properly
            fonts: [
              // Customizes the font for the payment elements
              {
                src: 'https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2',
                family: 'AxLLCircular',
                weight: 400,
              },
            ],
          },
          successUrl: 'https://wx.playhy.com/#/paySuccess?url_type=rxhj', // Must be HTTPS sites
          failUrl: 'https://wx.playhy.com/#/rxhj', // Must be HTTPS sites
        });
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    //stripe支付
    payByStripe(orderNum) {
      stripePayment({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: 'https://wx.playhy.com/#/rxhj',
        redirect_url: 'https://wx.playhy.com/#/paySuccess?url_type=rxhj',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    //连连支付
    payByLianLian(orderNum) {
      payLianLian({
        order_num: orderNum,
        user_id: this.user_id,
        cancel_url: 'https://wx.playhy.com/#/rxhj',
        redirect_url: 'https://wx.playhy.com/#/paySuccess',
      }).then((response) => {
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // 微信
    payWx(orderNum) {
      payWxRedirect(orderNum).then((response) => {
        Toast.clear(true);
        console.log(response)
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // PayPal
    payPaypal(orderNum) {
      payMix({
        number: orderNum,
        user_id: this.user_id,
        pay_type: parseInt(this.payTypeId),
        return_url: "https://api.hzarcticwolf.com/third/v1/offaccount/execpaypal?url_type=rxhj"
      }).then((res) => {
        console.log(res)
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "跳转支付页面...",
          forbidClick: true,
          duration: 3000,
        });
      }).catch(() => {
        this.$toast.fail('发起支付失败')
      });
    },
    // PayPal谷歌支付
    googlePay(orderNum) {
      getUsdMoney({
        order_num: orderNum
      }).then((res) => {
        Toast.clear(true)
        let productName = encodeURIComponent(res.props_name)
        let url = `https://wx.playhy.com/pay/googlePay.html?number=${orderNum}&user_id=${this.user_id}&moneyUsd=${res.money_usd}&productName=${productName}&pay_type=${parseInt(this.payTypeId)}&url=rxhj`
        window.location.href = url
      }).catch(() => {
        this.$toast.fail('请重试')
        Toast.clear(true)
      });

    },
    // PayPal苹果支付
    applePay(orderNum) {
      getUsdMoney({
        order_num: orderNum
      }).then((res) => {
        Toast.clear(true)
        let productName = encodeURIComponent(res.props_name)
        let url = `https://wx.playhy.com/pay/apple.html?number=${orderNum}&user_id=${this.user_id}&moneyUsd=${res.money_usd}&productName=${productName}&pay_type=${parseInt(this.payTypeId)}&url=rxhj`
        window.location.href = url
      }).catch(() => {
        this.$toast.fail('请重试')
        Toast.clear(true)
      });
    },
    // PayPal银行卡支付页面请求
    payPaypalCard(orderNum) {
      this.$router.push({
        path: "/paypalcard",
        query: {
          number: orderNum,
          user_id: this.user_id,
          pay_type: parseInt(this.payTypeId),
          url: 'rxhj'
        }
      });
      Toast.clear(true)
    },
    // 支付宝
    payAlipay(orderNum) {
      // 跳转中间页
      Toast.clear(true);
      console.log("跳转支付中间页");
      const key = Base64.encode(
        JSON.stringify({
          user_id: this.user_id,
          number: orderNum,
          pay_type: parseInt(this.payTypeId),
        })
      )
      this.$router.push({
        path: "/rxhj/mid",
        query: {
          key: key,
        },
      })
    },
    // mycard
    payByMyCard(orderNum) {
      payMycard({
        order_num: orderNum,
        user_id: this.user_id,
        type: 'official',
        return_url: "rxhj",
      }).then((res) => {
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "跳转支付页面...",
          forbidClick: true,
          duration: 3000,
        });
      }).catch((e) => {
        this.$toast.fail(e.message)
      });
    },
    // 保存用户信息
    saveUserRole() {
      localStorage.payTypeId = this.payTypeId
      const account_item = {
        account: this.account,
        user_id: this.user_id,
        user_email: this.user_email == undefined || this.user_email == '' ? this.email : this.user_email,
        // osId: this.osId,
        // osText: this.osText,
        // gameText: this.gameText,
        // gameList: this.gameList,
        game_id: this.game_id,
        serverText: this.serverText,
        server_id: this.server_id,
        getServerList: this.getServerList,
        roleText: this.roleText,
        roleId: this.roleId,
        role_id: this.role_id,
        roleList: this.roleList,
        sale_info: this.sale_info,
      };
      //最近充值的账号
      localStorage.rxhjAccountInfo = JSON.stringify(account_item);
      if (this.accountList.indexOf(this.account) === -1) {
        this.accountList.push(this.account)
      }
      console.log("accountList", JSON.stringify(this.accountList));
      if (localStorage.rxhj_info) {
        let rxhj_info =
          typeof localStorage.rxhj_info === "string"
            ? JSON.parse(localStorage.rxhj_info)
            : localStorage.rxhj_info
        rxhj_info[this.account] = account_item
        localStorage.rxhj_info = JSON.stringify(rxhj_info)
      } else {
        localStorage.rxhj_info = JSON.stringify({
          [this.account]: account_item,
        })
      }
    }
  },
};
</script>

<style>
.pay_icon {
  width: 35px;
  height: 44px;
  float: left;
}

.pay_desc {
  float: left;
  display: block;
  line-height: 44px;
  margin-left: 5px;
}

.pay_paypal_desc {
  float: left;
  display: block;
  line-height: 20px;
  margin-left: 5px;
  width: 262px;
}

.pay_paypal_tip {
  color: red;
}

.title_coupon {
  margin-right: 5px;
}

/* .van-coupon-list__list {
  height: 100% !important;
} */
</style>
