//import manager from '@/utils/manager'
import api from '@/utils/api'

/**
 * 获取用户信息
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function accountInfo(params) {
    return api.get('wx/v1/rxhj/account', {params: params})
}

/**
 * 获取游戏列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function gameList(params) {
    return api.get('wx/v1/rxhj/game', {params: params})
}

/**
 * 获取角色列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function serverList(params) {
    return api.get('wx/v1/rxhj/server', {params: params})
}

/**
 * 获取区服列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function roleList(params) {
    return api.get('wx/v1/rxhj/role', {params: params})
}

/**
 * 获取档位列表
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function stripeMoneyList(params) {
    return api.post('stripe/moneyList', params)
}

/**
 * 获取拉起微信支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function pay(params) {
    params.type = 2;
    params.project_id = 'rxhj';
    return api.post('wx/v1/official/common/wxpay', params)
}

export function pay2(params) {
    params.type = 2;
    params.project_id = 'rxhj';
    return api.post('wx/v1/official/common/wxpayv2', params)
}

/**
 * 支付包支付或paypel支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payMix(params) {
    return api.post('wx/v1/official_account/pay', params)
}

/**
 * 公众号微信支付
 * @param orderNum
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payWxRedirect(orderNum) {
    //    最新版公众号微信支付
    return api.post('wx/v1/official/common/wxpay', {order_num: orderNum, type: 1, project_id: 'rxhj'})
}

/**
 * 连连支付
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}

/**
 * mycard台湾支付
 * @param params
 * @returns {*}
 */
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}

/**
 * 订单创建接口
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function commonCreateOrder(params) {
    return api.post('wx/v1/rxhj/common/jzac', params)
}

export function getServerList(params) {
    return api.get('wx/v1/rxhj/get_server', { params:params })
}

export function getRoleList(params) {
    return api.get('wx/v1/rxhj/get_role', { params:params })
}
export function getGameId(params) {
    return api.get('wx/v1/rxhj/get_game', { params:params })
}

/**
 * 档位获取
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function thirdProductList(params) {
    return api.post('wx/v1/rxhj/moneyList', params)
}
